/* global __LOCALE__ __MAPPED_LOCALE__ __MOMENT_LOCALE__ */
/* eslint-disable global-require, @typescript-eslint/no-var-requires */

window.JOUWWEB = window.JOUWWEB || {};
window.JOUWWEB.localization = window.JOUWWEB.localization || {};

if (__LOCALE__) {
    // Compile time (build).
    window.JOUWWEB.localization[`website-rendering:${__LOCALE__}`] =
        __MAPPED_LOCALE__ === 'en-GB'
            ? {}
            : require(`../../../../language/website-rendering.${__MAPPED_LOCALE__}.po`);
    if (__MOMENT_LOCALE__ !== 'en') {
        require(`moment/locale/${__MOMENT_LOCALE__}.js`);
    }
} else {
    // Run time (dev-server).
    const localeInformation = require('../../../../language/locale-information.json');
    const locale = window.JOUWWEB.websiteRendering.locale;
    const mappedLocale =
        localeInformation.mappedWebsiteRenderingLocales[locale] || locale;
    const momentLocale = localeInformation.momentLocaleMap[locale];

    // Note that this is an exact copy of above but with variables (that don't
    // allow resolution at compile time).
    window.JOUWWEB.localization[`website-rendering:${locale}`] =
        mappedLocale === 'en-GB'
            ? {}
            : require(`../../../../language/website-rendering.${mappedLocale}.po`);
    if (momentLocale !== 'en') {
        require(`moment/locale/${momentLocale}.js`);
    }
}
